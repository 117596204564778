import { m } from 'framer-motion'
import { type ComponentPropsWithRef, type FC, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { LazyMotion } from '../lazy-motion/lazy-motion'
import { Caption } from '../typography/caption'

export interface UnreadCountProps {
  count?: string | null
  disableAnimation?: boolean
  shouldAnimate?: boolean
}

export const UnreadCount: FC<
  UnreadCountProps & ComponentPropsWithRef<'div'>
> = ({
  children,
  className,
  count,
  disableAnimation = false,
  shouldAnimate = false,
  ...attributes
}) => {
  const ANIMATION_DURATION = 0.2

  const prevCount = useRef(count)

  useEffect(() => {
    prevCount.current = count
  })

  const initial = prevCount.current
    ? false
    : {
        scale: disableAnimation ? 1 : 0.5,
        opacity: disableAnimation ? 1 : 0.2,
      }

  if (!count) {
    return <>{children}</>
  }

  return (
    <LazyMotion>
      <div
        className="relative"
        style={{
          display: 'inherit',
          flexDirection: 'inherit',
          alignItems: 'inherit',
          justifyContent: 'inherit',
        }}
      >
        {children}
        <div
          className={twMerge('absolute top-1 -right-2', className)}
          {...attributes}
        >
          <m.div
            initial={initial}
            className={twMerge(
              'flex items-center justify-center rounded-full border-2 border-solid border-white bg-pohutukawa min-h-6 min-w-6',
              shouldAnimate &&
                'after:absolute after:h-[110%] after:w-[110%] after:animate-[alert-pulse_2s_infinite] after:rounded-[50%] after:border-[1.5px] after:border-solid after:border-pohutukawa after:content-[""]',
            )}
            animate={{
              scale: 1,
              opacity: 1,
            }}
            transition={{
              duration: disableAnimation ? 0 : ANIMATION_DURATION,
            }}
          >
            <Caption className="min-w-[1rem] px-1 text-center leading-none text-white">
              <strong>{count}</strong>
            </Caption>
          </m.div>
        </div>
      </div>
    </LazyMotion>
  )
}
