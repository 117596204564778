import type { FC, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Paths } from '../../../config/app'
import {
  addValueToLocalStorage,
  removeValueFromLocalStorage,
} from '../../../service/storage'
import RedirectFromSearch from '../../ui-components/redirect-from-search/redirect-from-search'
import useIsLoggedIn from '../use-is-logged-in/use-is-logged-in'

export interface ProtectedPageProps {
  children?: ReactNode
}

const ProtectedPage: FC<ProtectedPageProps> = ({ children }) => {
  const isLoggedIn = useIsLoggedIn()
  const { search, pathname } = useLocation()

  if (!isLoggedIn) {
    addValueToLocalStorage('redirect-after-login', pathname)

    return <Navigate to={{ pathname: Paths.Login, search }} />
  }

  removeValueFromLocalStorage('redirect-after-login')

  return <RedirectFromSearch>{children}</RedirectFromSearch>
}

export default ProtectedPage
