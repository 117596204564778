import type { FC, HTMLProps } from 'react'
import { twMerge } from 'tailwind-merge'

type DividerProps = HTMLProps<HTMLDivElement>

export const Divider: FC<DividerProps> = ({ className, ...attributes }) => {
  return (
    <div className={twMerge('w-full border-b', className)} {...attributes} />
  )
}
