import { Caption, Icon, LazyMotion } from '@katoa/anatomy'
import { m } from 'framer-motion'
import { type ComponentPropsWithoutRef, type FC, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import { v4 as uuid } from 'uuid'

export interface ToggleProps extends ComponentPropsWithoutRef<'button'> {
  isActive?: boolean
  iconOff?: string
  iconOn?: string
  label?: string
  onToggle?: () => void
  title: string
}

export const Toggle: FC<ToggleProps> = ({
  className,
  isActive = false,
  iconOff,
  iconOn,
  onToggle,
  label = '',
  title,
}) => {
  const handleClick = (): void => {
    if (onToggle) {
      onToggle()
    }
  }

  const generatedID = useMemo(() => {
    return uuid()
  }, [])

  return (
    <LazyMotion advanced={true}>
      <button
        type="button"
        className={twMerge(
          'relative w-max cursor-pointer px-0 py-1',
          className,
        )}
        onClick={handleClick}
        tabIndex={0}
        role="switch"
        aria-checked={isActive}
        title={title}
        aria-describedby={generatedID}
        data-testid="alerts-toggle"
      >
        <div
          className={twMerge(
            'flex items-center rounded-full bg-dust transition-colors',
            isActive ? 'bg-kereru' : 'flex-row-reverse',
          )}
        >
          <Caption
            className={twMerge('px-2', isActive ? 'text-white' : 'text-storm')}
            id={generatedID}
          >
            <strong>{isActive ? `${label} On` : `${label} Off`}</strong>
          </Caption>

          <m.div
            className="-m-1 flex items-center justify-center rounded-full bg-white p-[6px] shadow-md"
            layout={true}
            transition={{
              duration: 0.2,
              type: 'tween',
            }}
          >
            <Icon
              path={isActive ? iconOn : iconOff}
              size={2}
              colour={isActive ? 'kereru' : 'midnight'}
            />
          </m.div>
        </div>
      </button>
    </LazyMotion>
  )
}
