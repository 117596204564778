import type { FC, ReactElement, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import type { Colour } from '../../types'

export interface IllustrationProps {
  children: ReactNode
  highlight?: Colour | 'theme' | 'default'
}

export const Illustration: FC<IllustrationProps> = ({
  children,
  highlight = 'default',
  ...attributes
}): ReactElement => {
  return (
    <div
      className={twMerge(
        'h-[9.5rem] w-[9.5rem]',
        '[&>svg]:h-full [&>svg]:w-full',
        highlight === 'theme' &&
          '[--highlight-colour:var(--colour-theme-primary-background,var(--colour-brand-kereru))]',
        highlight === 'midnight' &&
          '[--highlight-colour:var(--colour-brand-midnight)]',
        highlight === 'pohutukawa' &&
          '[--highlight-colour:var(--colour-brand-pohutukawa)]',
        highlight === 'kereru' &&
          '[--highlight-colour:var(--colour-brand-kereru)]',
        highlight === 'storm' &&
          '[--highlight-colour:var(--colour-brand-storm)]',
        highlight === 'stone' &&
          '[--highlight-colour:var(--colour-brand-stone)]',
        highlight === 'storm-thin-1' &&
          '[--highlight-colour:var(--colour-brand-storm-thin-1)]',
        highlight === 'storm-100' &&
          '[--highlight-colour:var(--colour-brand-storm-100)]',
        highlight === 'storm-400' &&
          '[--highlight-colour:var(--colour-brand-storm-400)]',
        highlight === 'kowhai' &&
          '[--highlight-colour:var(--colour-brand-kowhai)]',
        highlight === 'kiwi' && '[--highlight-colour:var(--colour-brand-kiwi)]',
        highlight === 'white' &&
          '[--highlight-colour:var(--colour-brand-white)]',
        highlight === 'mist' && '[--highlight-colour:var(--colour-brand-mist)]',
        highlight === 'wind' && '[--highlight-colour:var(--colour-brand-wind)]',
        highlight === 'dust' && '[--highlight-colour:var(--colour-brand-dust)]',
        highlight === 'current' &&
          '[--highlight-colour:var(--colour-brand-current)]',
        highlight === 'default' && '[--highlight-colour:hsl(214,10%,73%)]',
      )}
      {...attributes}
    >
      {children}
    </div>
  )
}
