import type { FC, ReactElement, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { Stack } from '../stack/stack'

export interface EmptyProps {
  children?: ReactNode
  className?: string
}

export const Empty: FC<EmptyProps> = ({
  children,
  className,
  ...attributes
}): ReactElement => {
  return (
    <Stack
      spacing="half"
      className={twMerge('mx-auto px-4 text-center [&>*]:mx-auto', className)}
      {...attributes}
    >
      {children}
    </Stack>
  )
}
