import { type ComponentType, type LazyExoticComponent, lazy } from 'react'

type LazyInputType = Promise<{
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  default: ComponentType<any>
}>

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type LazyReturnType = LazyExoticComponent<ComponentType<any>>

type Retry = (
  fn: () => LazyInputType,
  retriesLeft?: number,
  interval?: number,
) => LazyInputType

const NUMBER_OF_RETRIES = 5
const RETRY_INTERVAL = 1000 // in ms

export const retry: Retry = (
  fn,
  retriesLeft = NUMBER_OF_RETRIES,
  interval = RETRY_INTERVAL,
) =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)

            return
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })

type LazyWithRetry = (fn: () => LazyInputType) => LazyReturnType

const lazyWithRetry: LazyWithRetry = (fn) => lazy(() => retry(fn))

export default lazyWithRetry
