import { Icon, UnreadCount } from '@katoa/anatomy'
import type { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export interface NavigationItemProps {
  shouldAnimate?: boolean
  count?: string
  'data-testid': string
  icon: string
  isEnabled?: boolean
  title: string
  to: string
}

export const NavigationItem: FC<NavigationItemProps> = ({
  shouldAnimate = false,
  count,
  'data-testid': dataTestId,
  icon,
  isEnabled = true,
  title,
  to,
}) => {
  if (!isEnabled) {
    return null
  }

  return (
    <li>
      <NavLink
        to={to}
        className={({ isActive }): string => {
          return twMerge(
            isActive
              ? 'text-kereru lg:font-semibold'
              : 'text-gray-500 hover:text-blue-950',
            'relative flex flex-col items-center p-2 text-xs font-medium',
            'md:px-6',
            'lg:pl-4 lg:pr-12',
            'lg:flex-row lg:text-base',
          )
        }}
        data-testid={dataTestId}
      >
        <UnreadCount
          shouldAnimate={shouldAnimate}
          count={count}
          className="lg:relative lg:bottom-auto lg:left-2 lg:right-auto lg:top-[1.6px]"
          data-dd-action-name="navigation unread count"
        >
          <Icon path={icon} size={3} />
          <span className="lg:ml-2">{title}</span>
        </UnreadCount>
      </NavLink>
    </li>
  )
}
