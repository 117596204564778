import { datadogLogs } from '@datadog/browser-logs'
import { type FC, type ReactNode, useEffect, useRef, useState } from 'react'
import OneSignal from 'react-onesignal'
import { getValueFromLocalStorage } from '../../service/storage'

const { VITE_ONE_SIGNAL_APP_ID } = import.meta.env

export interface OneSignalPageProps {
  children?: ReactNode
}

/**
 * We need a repeatable way to identify OneSignal accounts in order to send clinicians
 * notifications. At the same time, OneSignal's security does not instill enough confidence
 * for us to store user data on their servers.
 *
 * Our solution is to hash emails using SHA-256 to create external IDs that can be sent
 * to OneSignal
 *
 * @see https://vital-software.atlassian.net/wiki/spaces/SEC/pages/1810432150/OneSignal
 *
 * @param clinicianEmail
 * @returns a SHA-256 hash of a clinician's email ie their unique Vital ID
 */
const generateExternalId = async (clinicianEmail: string): Promise<string> => {
  const encoder = new TextEncoder()
  const data = encoder.encode(clinicianEmail)

  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  const NUMERIC_TO_STRING_RADIX = 16
  const externalId = hashArray
    .map((b) => b.toString(NUMERIC_TO_STRING_RADIX).padStart(2, '0'))
    .join('')

  return externalId
}

const OneSignalProvider: FC<OneSignalPageProps> = ({ children }) => {
  const appId = VITE_ONE_SIGNAL_APP_ID

  const [idToken, setIdToken] = useState(
    getValueFromLocalStorage<string>('id-token'),
  )
  const [email, setEmail] = useState(getValueFromLocalStorage<string>('email'))
  const [facility, setFacility] = useState(
    getValueFromLocalStorage<string>('facilityId'),
  )

  const oneSignalInitialized = useRef<boolean>(false)

  const onStorageChange = (): void => {
    setIdToken(getValueFromLocalStorage<string>('id-token'))
    setEmail(getValueFromLocalStorage<string>('email'))
    setFacility(getValueFromLocalStorage<string>('facilityId'))
  }

  window.addEventListener('storage', onStorageChange)

  useEffect(() => {
    const initOneSignal = async (
      stringToHashForExternalId: string,
    ): Promise<void> => {
      const externalId = await generateExternalId(stringToHashForExternalId)

      if (appId && externalId && facility) {
        OneSignal.init({ appId })

        OneSignal.setExternalUserId(externalId)
        OneSignal.sendTag('facility', facility)
        oneSignalInitialized.current = true
      }
    }

    if (!oneSignalInitialized.current && idToken && email && facility) {
      try {
        initOneSignal(email)
      } catch (error) {
        if (error instanceof Error) {
          datadogLogs.logger.warn('Error when intializing OneSignal', {}, error)
        } else {
          datadogLogs.logger.error('Error when intializing OneSignal')
        }
      }
    }
  }, [appId, idToken, email, facility])

  return <>{children}</>
}

export default OneSignalProvider
