import type { FC, HTMLProps, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export interface DialogFooterProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode
}

export const DialogFooter: FC<DialogFooterProps> = ({
  children,
  className,
  ...attributes
}) => (
  <footer
    className={twMerge(
      'sticky bottom-0 z-20 mx-5 flex flex-wrap items-center border-0 bg-white py-5',
      className,
    )}
    data-testid="component:dialog:footer"
    {...attributes}
  >
    {children}
  </footer>
)
