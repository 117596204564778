import { type ApolloError, useQuery } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { Status } from '@katoa/anatomy'
import { type FC, type ReactNode, Suspense } from 'react'
import { useParams } from 'react-router-dom'
import ConnectionError from '../../app/ui-components/connection-error/connection-error'
import DelayedLoader from '../../app/ui-components/delayed-loader/delayed-loader'
import GlobalHeader from '../../app/ui-components/global-header/global-header'
import Navigation from '../../app/ui-components/navigation/navigation'
import { LOGGED_IN_LAYOUT_QUERY } from '../../graphql/queries/logged-in-layout'
import type { LoggedInLayoutQuery } from '../../graphql/type/LoggedInLayoutQuery'

export interface LoggedInLayoutProps {
  children?: ReactNode
}

const LoggedInLayout: FC<LoggedInLayoutProps> = ({ children }) => {
  const { facilityId } = useParams()

  const { data, error, loading } = useQuery<LoggedInLayoutQuery>(
    LOGGED_IN_LAYOUT_QUERY,
    {
      onError: ({ graphQLErrors }: ApolloError) => {
        if (graphQLErrors) {
          // biome-ignore lint/complexity/noForEach: <explanation>
          graphQLErrors.forEach((graphQlError) => {
            datadogLogs.logger.warn(
              'GraphQL error while running the logged in layout query.',
              graphQlError,
            )
          })
        }
      },
      variables: {
        faciltyInput: {
          facilityId,
        },
        clinicianUserFacilityConfigurationInput: {
          facilityId,
        },
      },
      skip: facilityId === 'alerts',
    },
  )

  const facility = data?.facilityById
  const facilityConfiguration = data?.clinicianUserFacilityConfiguration

  if (facility) {
    datadogRum.setGlobalContextProperty('facilityId', facility.id)
  }

  if (facilityConfiguration) {
    datadogRum.setUser({
      email: facilityConfiguration?.email,
    })
  }

  if (loading) {
    return (
      <div className="m-auto">
        <Status status="loading" size="moa" />
      </div>
    )
  }

  return (
    <main className="flex min-h-full flex-col">
      <div className="border-b border-gray-300 p-6">
        <GlobalHeader
          showAlertsToggle={facility?.enabledFeatures?.serviceRequests}
          userName={facilityConfiguration?.label}
        />
      </div>
      <div className="flex grow flex-col md:flex-row">
        <div
          className={`
            flex grow bg-gray-50 pt-4
            md:border-l md:px-6 md:pt-6
            lg:pt-8 min-w-0
          `}
        >
          {error ? (
            <ConnectionError />
          ) : (
            <Suspense fallback={<DelayedLoader />}>{children}</Suspense>
          )}
        </div>
        <div className="bg-white flex-[0] md:flex md:flex-col sticky bottom-0 md:top-0 border-t md:border-0 md:pt-6 border-gray-300 md:order-first">
          <Navigation />
          {facility?.assetUrls?.logo && (
            <img
              src={facility?.assetUrls?.logo}
              alt={facility?.name || 'Unknown Facility'}
              className="hidden md:block md:sticky md:bottom-6 md:px-2 lg:px-4"
              data-testid="component:logo"
            />
          )}
        </div>
      </div>
    </main>
  )
}

export default LoggedInLayout
