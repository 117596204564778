import type { FC } from 'react'
import { Illustration, type IllustrationProps } from './illustration'

export const NoFacilityIllustration: FC<Omit<IllustrationProps, 'children'>> = (
  props,
) => {
  return (
    <Illustration {...props}>
      {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
      <svg
        width="150"
        height="150"
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="component:illustration:no-facility"
      >
        <path
          opacity=".6"
          d="M107.25 60.75H125.25C128.564 60.75 131.25 63.4363 131.25 66.75V127.5H107.25V60.75Z"
          fill="url(#paint0_linear_636_1471)"
        />
        <rect x="114.805" y="74.48" width="6.04" height="15.281" fill="#fff" />
        <rect x="114.805" y="98.93" width="6.04" height="15.281" fill="#fff" />
        <path
          opacity=".6"
          d="M17.25 66.75C17.25 63.4363 19.9363 60.75 23.25 60.75H41.25V127.5H17.25V66.75Z"
          fill="url(#paint1_linear_636_1471)"
        />
        <rect x="27.125" y="75.063" width="6.04" height="15.281" fill="#fff" />
        <rect x="27.125" y="99.513" width="6.04" height="15.281" fill="#fff" />
        <path
          d="M109.521 101.33C108.811 100.223 107.174 100.223 106.464 101.33C105.571 102.722 103.493 109.322 103.493 112.636C103.493 115.097 104.617 116.937 105.743 118.183C106.133 118.614 106.522 118.977 106.868 119.266V127.5H109.118V119.266C110.428 118.164 112.493 115.951 112.493 112.636C112.493 109.322 110.415 102.722 109.521 101.33Z"
          fill="#B3B9C1"
        />
        <path
          d="M136.556 76.613C135.136 74.4623 131.862 74.4623 130.442 76.613C128.656 79.3201 124.5 92.153 124.5 98.5985C124.5 103.383 126.748 106.96 129 109.384C129.781 110.222 130.559 110.927 131.25 111.489V127.5H135.75V111.489C138.371 109.347 142.5 105.044 142.5 98.5985C142.5 92.153 138.344 79.3201 136.556 76.613Z"
          fill="#687485"
        />
        <path
          d="M19.3312 91.1521C18.3256 89.616 16.0067 89.616 15.001 91.1521C13.7356 93.0858 10.7919 102.252 10.7919 106.856C10.7919 110.274 12.3841 112.829 13.9794 114.56C14.5324 115.158 15.0839 115.662 15.5731 116.064V127.5H18.7606V116.064C20.6174 114.534 23.5419 111.46 23.5419 106.856C23.5419 102.252 20.5982 93.0858 19.3312 91.1521Z"
          fill="#64788B"
        />
        <path
          d="M41.25 35.25C41.25 31.9363 43.9363 29.25 47.25 29.25H101.25C104.564 29.25 107.25 31.9363 107.25 35.25V128.25H41.25V35.25Z"
          fill="url(#paint2_linear_636_1471)"
        />
        <path
          d="M0 127.5C0 123.358 3.35786 120 7.5 120H142.5C146.642 120 150 123.358 150 127.5V127.5C150 131.642 146.642 135 142.5 135H7.5C3.35787 135 0 131.642 0 127.5V127.5Z"
          fill="#687485"
        />
        <rect x="89.019" y="74.905" width="6.231" height="15.591" fill="#fff" />
        <rect x="89.019" y="49.442" width="6.231" height="15.591" fill="#fff" />
        <rect x="89.019" y="99.851" width="6.231" height="15.591" fill="#fff" />
        <rect x="54.75" y="74.905" width="6.231" height="15.591" fill="#fff" />
        <rect x="54.75" y="49.442" width="6.231" height="15.591" fill="#fff" />
        <rect x="54.75" y="99.851" width="6.231" height="15.591" fill="#fff" />
        <rect x="71.885" y="74.905" width="6.231" height="15.591" fill="#fff" />
        <rect x="71.885" y="49.442" width="6.231" height="15.591" fill="#fff" />
        <rect x="71.885" y="99.851" width="6.231" height="15.591" fill="#fff" />
        <path
          d="M61.5 15.4489C61.5 12.9637 63.5147 10.9489 66 10.9489H84C86.4853 10.9489 88.5 12.9637 88.5 15.4489V31.9489C88.5 34.4342 86.4853 36.4489 84 36.4489H66C63.5147 36.4489 61.5 34.4342 61.5 31.9489V15.4489Z"
          fill="var(--highlight-colour)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66 7.94894H84C88.1421 7.94894 91.5 11.3068 91.5 15.4489V31.9489C91.5 36.0911 88.1421 39.4489 84 39.4489H66C61.8579 39.4489 58.5 36.0911 58.5 31.9489V15.4489C58.5 11.3068 61.8579 7.94894 66 7.94894ZM66 10.9489C63.5147 10.9489 61.5 12.9637 61.5 15.4489V31.9489C61.5 34.4342 63.5147 36.4489 66 36.4489H84C86.4853 36.4489 88.5 34.4342 88.5 31.9489V15.4489C88.5 12.9637 86.4853 10.9489 84 10.9489H66Z"
          fill="var(--highlight-colour)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.7661 16.5271H73.2293V22.2683H67.6714L67.6714 25.9218H73.2293V31.6628H76.7661V25.9218H82.3239V22.2683H76.7661V16.5271Z"
          fill="#fff"
        />
        <defs>
          <linearGradient
            id="paint0_linear_636_1471"
            x1="119.25"
            y1="60.75"
            x2="119.25"
            y2="127.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#687485" />
            <stop offset="1" stopColor="#687485" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_636_1471"
            x1="29.25"
            y1="60.75"
            x2="29.25"
            y2="127.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#687485" />
            <stop offset="1" stopColor="#687485" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_636_1471"
            x1="74.25"
            y1="29.25"
            x2="74.25"
            y2="128.25"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B3B9C1" />
            <stop offset="1" stopColor="#B3B9C1" stopOpacity=".2" />
          </linearGradient>
        </defs>
      </svg>
    </Illustration>
  )
}
