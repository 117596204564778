import { Slot } from '@radix-ui/react-slot'
import type { ComponentPropsWithoutRef, FC } from 'react'
import { twMerge } from 'tailwind-merge'

interface BodyProps extends ComponentPropsWithoutRef<'div'> {
  /**
   * Change the default rendered element for the one passed as a child, merging their props and behavior.
   */
  asChild?: boolean
  secondary?: boolean
  tertiary?: boolean
}

export const Body: FC<BodyProps> = ({
  asChild,
  children,
  secondary,
  tertiary,
  className,
  ...attributes
}) => {
  const Element = asChild ? Slot : 'div'

  return (
    <Element
      {...attributes}
      className={twMerge(
        'text-base leading-7',
        secondary && 'text-midnight',
        tertiary && 'text-stone',
        className,
      )}
    >
      {children}
    </Element>
  )
}
