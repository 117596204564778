// Auth0 URL Config

const { VITE_AUTH0_CLIENT_ID, VITE_AUTH0_DOMAIN } = import.meta.env

const AUTH0_REDIRECT_URI = `${window.location.origin}/auth0-callback`
const AUTH0_RESPONSE_TYPE = 'code'
const AUTH0_SCOPE = [
  'offline_access',
  'openid',
  'email',
  'vital/health_entity_id',
].join('%20')

// Auth0 URL for SSO
export const SSO_URL = `//${VITE_AUTH0_DOMAIN}/authorize?response_type=${AUTH0_RESPONSE_TYPE}&client_id=${VITE_AUTH0_CLIENT_ID}&redirect_uri=${AUTH0_REDIRECT_URI}&scope=${AUTH0_SCOPE}`

// Auth0 URL for OTP Code Login
export const OTP_URL = `${SSO_URL}&connection=email`

/**
 * Auth0 uses a cookie it has saved on the user's browser to look them up in auth0 and then destroy their session.
 * It's important that we actually redirect them to Auth0's logout endpoint, (Auth0 will redirect them back to our login page) in order for this process to work properly.
 *
 * If the Auth0 session is not cleared, the user is able to request another authorization code from the Auth0 callback,
 * which will allow them to exchange it for valid tokens again.
 * They would be able to do this without needing an OTP Email code or even to be signed in to their Identity Provider.
 */
export const AUTH0_LOGOUT_URL = `//${VITE_AUTH0_DOMAIN}/v2/logout?client_id=${VITE_AUTH0_CLIENT_ID}&returnTo=${window.location.origin}`
