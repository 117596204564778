import { type Dispatch, type SetStateAction, useState } from 'react'
import { STORAGE_KEY } from '../config/app'

type LocalStorageKey =
  | 'email'
  | 'facilityId'
  | 'id-token'
  | 'redirect-after-login'
  | 'refresh-token'
  | 'service-requests-assignee-selector-id'
  | 'visits-status-selector-ids'
  | 'hide-notification-groups'

/**
 * React Hook to sync 'state' with 'storage'
 */
export const useLocalStorage = <S = undefined>(
  key: LocalStorageKey,
  initialValue?: undefined,
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
): [S | undefined, Dispatch<SetStateAction<Function | S | undefined>>] => {
  const storageKeyWithPrefix = `${STORAGE_KEY}:${key}`

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = localStorage.getItem(storageKeyWithPrefix)

      // Parse stored JSON or if none return initialValue
      return item && item !== 'undefined'
        ? (JSON.parse(item) as S)
        : initialValue
    } catch {
      // If error, also return initialValue
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that
  // persists the new value to localStorage.
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  const setValue = (value: Function | S | undefined): void => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value

    // Save state
    setStoredValue(valueToStore)

    // Save to local storage
    localStorage.setItem(storageKeyWithPrefix, JSON.stringify(valueToStore))
  }

  return [storedValue, setValue]
}

/**
 * Remove from Local Storage
 */
export const removeValueFromLocalStorage = (key: LocalStorageKey): void => {
  localStorage.removeItem(`${STORAGE_KEY}:${key}`)
}

/**
 * Retrieve from Local Storage
 */
export const addValueToLocalStorage = <S>(
  key: LocalStorageKey,
  value: S,
): void => {
  localStorage.setItem(`${STORAGE_KEY}:${key}`, JSON.stringify(value))
  window.dispatchEvent(new Event('storage'))
}

/**
 * Retrieve from Local Storage
 */
export const getValueFromLocalStorage = <S>(
  key: LocalStorageKey,
): S | undefined => {
  try {
    // Get from local storage by key
    const item = localStorage.getItem(`${STORAGE_KEY}:${key}`)

    // Parse stored JSON or if none return initialValue
    return item && item !== 'undefined' ? (JSON.parse(item) as S) : undefined
  } catch {
    // If error, return undefined
    return undefined
  }
}

type SessionStorageKey = 'tracking-id:tab'

/**
 * Add to Session Storage
 */
export const addValueToSessionStorage = <S>(
  key: SessionStorageKey,
  value: S,
): void => {
  sessionStorage.setItem(`${STORAGE_KEY}:${key}`, JSON.stringify(value))
}

/**
 * Retrieve from Session Storage
 */
export const getValueFromSessionStorage = <S>(
  key: SessionStorageKey,
): S | undefined => {
  try {
    // Get from session storage by key
    const item = sessionStorage.getItem(`${STORAGE_KEY}:${key}`)

    return item && item !== 'undefined' ? (JSON.parse(item) as S) : undefined
  } catch {
    // If error, return undefined
    return undefined
  }
}

/**
 * Remove from Session Storage
 */
export const removeValueFromSessionStorage = (key: SessionStorageKey): void => {
  sessionStorage.removeItem(`${STORAGE_KEY}:${key}`)
  window.dispatchEvent(new Event('storage'))
}
