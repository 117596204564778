import { LazyMotion as FramerLazyMotion } from 'framer-motion'
import type { FC, ReactNode } from 'react'

export const LazyMotion: FC<{ children: ReactNode; advanced?: boolean }> = ({
  children,
  advanced = false,
}) => {
  const loadFeatures = () =>
    import('./dom-animation').then((res) => res?.default)
  const loadAdvancedFeatures = () =>
    import('./dom-max').then((res) => res?.default)

  return (
    <FramerLazyMotion
      features={advanced ? loadAdvancedFeatures : loadFeatures}
      strict={true}
    >
      {children}
    </FramerLazyMotion>
  )
}
