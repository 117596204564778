import type { FC, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import type { Size } from '../../types'

export interface ConstrainProps {
  children?: ReactNode
  className?: string
  size: Exclude<Size, 'kea'> | 'none' | 'xsmall'
}

export const Constrain: FC<ConstrainProps> = ({
  children,
  className,
  size,
}) => {
  return (
    <div
      className={twMerge(
        'relative mx-auto flex w-full flex-col',
        size === 'moa' && 'max-w-7xl',
        size === 'kiwi' && 'max-w-3xl',
        size === 'piwakawaka' && 'max-w-md',
        size === 'xsmall' && 'max-w-sm',
        size === 'none' && 'max-w-none',
        className,
      )}
    >
      {children}
    </div>
  )
}

export default Constrain
