import { m } from 'framer-motion'
import { type FC, type RefObject, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { LazyMotion } from '../../lazy-motion/lazy-motion'
import Bar from './bar'

interface MarkerProps {
  position: number
  className?: string
}

const Marker = forwardRef<HTMLDivElement, MarkerProps>(
  ({ className, position }, ref) => {
    return (
      <div
        ref={ref}
        style={{
          left: `${position}%`,
          top: 'calc(50% - (0.875rem / 2) - 1px)',
        }}
        className={twMerge(
          'absolute box-content h-[0.875rem] w-[0.25rem] -translate-x-1/2 rounded-full border border-white',
          className,
        )}
      />
    )
  },
)

export const MARKER_POSITIONS = {
  start: 15,
  middle: 50,
  end: 85,
}

export type Status = 'positive' | 'negative' | 'neutral' | 'target' | 'stable'

export type MarkerPosition = 'start' | 'middle' | 'end'

interface GaugeBarProps {
  status: Status
  markers?:
    | {
        position: MarkerPosition
        status?: Status
        visible?: boolean
        ref: RefObject<HTMLDivElement>
      }[]
    | null
}

const GaugeBar: FC<GaugeBarProps> = ({ status, markers }) => {
  return (
    <LazyMotion>
      <Bar
        className={twMerge(
          status === 'positive' && 'bg-[--colour-status-positive]',
          status === 'negative' && 'bg-[--colour-status-negative]',
          status === 'neutral' && 'bg-[--colour-status-neutral]',
          status === 'target' && 'bg-[--colour-status-target]',
          status === 'stable' && 'bg-[--colour-status-stable]',
        )}
        large={['positive', 'negative'].includes(status)}
        dataTestId={`gauge-bar:${status}`}
      >
        {markers?.map((marker, idx) => (
          <m.div
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            key={idx}
            initial={marker.visible === false ? { opacity: 0 } : { opacity: 1 }}
            animate={marker.visible === false ? { opacity: 0 } : { opacity: 1 }}
          >
            <Marker
              ref={marker.ref}
              position={MARKER_POSITIONS[marker.position]}
              data-testid="gauge-bar-marker"
              className={twMerge(
                (marker.status ?? status) === 'positive' &&
                  'bg-[--colour-status-positive]',
                (marker.status ?? status) === 'negative' &&
                  'bg-[--colour-status-negative]',
                (marker.status ?? status) === 'neutral' &&
                  'bg-[--colour-status-neutral]',
                (marker.status ?? status) === 'target' &&
                  'bg-[--colour-status-target]',
                (marker.status ?? status) === 'stable' &&
                  'bg-[--colour-status-stable]',
              )}
            />
          </m.div>
        ))}
      </Bar>
    </LazyMotion>
  )
}

export default GaugeBar
