import {
  Button,
  Empty,
  EmptyHeading,
  EmptyText,
  NoConnectionIllustration,
} from '@katoa/anatomy'
import { mdiSync } from '@mdi/js'
import type { ReactElement } from 'react'

const onError = (): void => window.location.reload()

const ConnectionError = (): ReactElement => {
  return (
    <Empty data-testid="visits:error">
      <NoConnectionIllustration highlight="mist" />
      <EmptyHeading>Connection Error</EmptyHeading>
      <EmptyText>
        Sorry, we are having trouble retrieving the data you are looking for.
      </EmptyText>
      <Button
        element="button"
        soft={true}
        intent="neutral"
        iconBefore={mdiSync}
        onClick={onError}
        data-testid="button:general-error-refresh"
      >
        Refresh and Try Again
      </Button>
    </Empty>
  )
}

export default ConnectionError
