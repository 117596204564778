import {
  Constrain,
  Empty,
  EmptyHeading,
  EmptyText,
  GeneralErrorIllustration,
} from '@katoa/anatomy'
import { type FC, useEffect } from 'react'
import track from '../../service/track/track'

export const GeneralErrorPage: FC = () => {
  useEffect(() => {
    document.title = 'Error Loading Page | Vital CareAssist'
    track('general-error-page-loaded')
  }, [])

  return (
    <Constrain size="piwakawaka">
      <Empty>
        <GeneralErrorIllustration highlight="kereru" />
        <EmptyHeading>Error Loading Page</EmptyHeading>
        <EmptyText>
          Something has gone wrong while loading the page. Our team has been
          notified of the issue and is looking into it.
        </EmptyText>
      </Empty>
    </Constrain>
  )
}
