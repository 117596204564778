import { mdiClose } from '@mdi/js'
import {
  type ChangeEvent,
  type ComponentPropsWithRef,
  type ReactElement,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'
import { v4 as uuid } from 'uuid'
import type { Affix } from '../../types'
import { Button } from '../button/button'
import { Icon } from '../icon/icon'
import { RemainingCharacters } from '../remaining-characters/remaining-characters'

export interface TextInputProps extends ComponentPropsWithRef<'input'> {
  append?: Affix | undefined
  className?: string | undefined
  disabled?: boolean | undefined
  hardLimit?: boolean | undefined
  id?: string | undefined
  onInputClear?: (() => void) | undefined
  onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined
  prepend?: Affix | undefined
  renderCounter?: boolean | undefined
  value?: string | undefined
  validation?: ((data: boolean) => void) | undefined
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      append,
      className,
      disabled,
      hardLimit = false,
      id,
      maxLength,
      onInputClear,
      onChange,
      prepend,
      renderCounter = true,
      value,
      validation,
      ...attributes
    },
    ref,
  ): ReactElement => {
    const htmlProps = {
      ...attributes,
    }

    const controlID = useMemo(() => {
      return id || uuid()
    }, [id])

    const counterID = useMemo(() => {
      return `character-counter_${uuid()}`
    }, [])

    const [currentValue, setCurrentValue] = useState<string>(value || '')

    const validateValue = (input: string) => {
      if (htmlProps.pattern) {
        const regex = new RegExp(htmlProps.pattern)

        if (validation) {
          if (!regex.test(input)) {
            validation(false)
          } else {
            validation(true)
          }
        }
      }
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
      if (value) {
        setCurrentValue(value)
        validateValue(value)
      } else {
        setCurrentValue('')
      }
    }, [value])

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
      setCurrentValue(event.currentTarget.value)
      validateValue(event.currentTarget.value)
      if (onChange) {
        onChange(event)
      }
    }

    return (
      <>
        <div
          className={twMerge(
            'relative flex w-full items-center rounded-md border-2 focus-within:border-kereru',
            disabled ? 'bg-gray-100 text-gray-400' : 'bg-white',
            className,
          )}
        >
          {prepend && (
            <div className="ml-2 text-gray-400">
              {prepend.type === 'string' && prepend.value}
              {prepend.type === 'icon' && prepend && (
                <Icon path={prepend.value} size={3} />
              )}
            </div>
          )}

          <input
            className={twMerge(
              'block w-full grow border-0 bg-transparent px-3 py-2 text-inherit outline-0 placeholder:text-gray-400 disabled:cursor-not-allowed',
              !currentValue &&
                '[&[type=date]]:opacity-45 [&[type=time]]:opacity-45',
            )}
            id={controlID}
            disabled={disabled}
            aria-controls={maxLength && renderCounter ? counterID : undefined}
            maxLength={hardLimit ? maxLength : undefined}
            onChange={handleChange}
            ref={ref}
            value={currentValue}
            {...htmlProps}
          />

          {append && (
            <div className="mr-2 text-gray-400">
              {append.type === 'string' && append.value}
              {append.type === 'icon' && append && (
                <Icon path={append.value} size={3} />
              )}
            </div>
          )}

          {onInputClear && (
            <Button
              aria-label="Clear"
              className="m-1 text-storm"
              collapsed={true}
              element="button"
              iconBefore={mdiClose}
              onClick={onInputClear}
              size="kiwi"
            />
          )}
        </div>

        {renderCounter && maxLength && (
          <RemainingCharacters
            id={counterID}
            maximum={maxLength}
            currentLength={currentValue?.length}
          />
        )}
      </>
    )
  },
)

TextInput.displayName = 'TextInput'
