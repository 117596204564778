import { mdiCheckboxMarkedCircleOutline, mdiLoading } from '@mdi/js'
import Icon from '@mdi/react'
import { type FC, type ReactNode, useContext } from 'react'
import { SlotButtonContext } from './context'

const Inner: FC<{ children?: ReactNode }> = ({ children }) => {
  const { loading, success, iconBefore, iconAfter } =
    useContext(SlotButtonContext)

  return (
    <>
      {(loading && (
        <Icon
          className="aspect-square h-[1.5em] animate-spin"
          data-testid="icon:loading"
          path={mdiLoading}
        />
      )) ||
        (success && (
          <Icon
            path={mdiCheckboxMarkedCircleOutline}
            className="aspect-square h-[1.5em] shrink-0"
          />
        )) ||
        (iconBefore && (
          <Icon
            path={iconBefore}
            className="aspect-square h-[1.5em] shrink-0"
          />
        ))}
      {children && (
        <div className="min-w-0 max-w-full text-center font-semibold">
          {children}
        </div>
      )}
      {iconAfter && (
        <Icon path={iconAfter} className="aspect-square h-[1.5em] shrink-0" />
      )}
    </>
  )
}

export { Inner }
