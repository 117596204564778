import { type ApolloError, useQuery } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import { Status } from '@katoa/anatomy'
import type { FC } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom'
import { Paths } from '../../config/app'
import { USER_PERMISSIONS_QUERY } from '../../graphql/queries/user-permissions-query'
import type { UserPermissions } from '../../graphql/type/UserPermissions'
import LoggedInLayout from '../../layouts/logged-in/logged-in'
import { GeneralErrorPage } from '../../pages/general-error/general-error'
import FacilityProvider from '../../providers/facility-provider/facility-provider'
import {
  addValueToLocalStorage,
  getValueFromLocalStorage,
} from '../../service/storage'
import lazy from '../../utility/lazy'
import { NotificationsRedirect } from '../redirects/notifications'

const Patients = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "patients" */ '../../pages/patients/patients'
    ),
)
const AlertsIndexPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "patients" */ '../../pages/alerts/alerts-index'
    ),
)

// Not Preloaded or Prefetched
const Settings = lazy(
  () =>
    import(
      /* webpackChunkName: "facility-settings" */ '../../pages/settings/settings'
    ),
)
const UserSettings = lazy(
  () =>
    import(
      /* webpackChunkName: "facility-settings" */ '../../pages/user-settings/user-settings'
    ),
)

const TypeformForm = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "form" */ '../../pages/typeform-form/typeform-form'
    ),
)

const NotFound = lazy(
  () =>
    import(
      /* webpackChunkName: "not-found" */ '../../pages/not-found/not-found'
    ),
)

export const FacilitySpecificRoutes: FC = () => {
  // This is temporary behaviour, until we get proper multi facility support.

  const { facilityId } = useParams()
  const { pathname } = useLocation()

  const currentFacilityId = getValueFromLocalStorage<string>('facilityId')

  const { data, error, loading } = useQuery<UserPermissions>(
    USER_PERMISSIONS_QUERY,
    {
      onError: ({ graphQLErrors }: ApolloError) => {
        if (graphQLErrors) {
          // biome-ignore lint/complexity/noForEach: <explanation>
          graphQLErrors.forEach((graphQlError) => {
            datadogLogs.logger.warn(
              "GraphQL error while trying to retrieve a Clinician's facility permissions.",
              graphQlError,
            )
          })
        }
      },
    },
  )

  if (loading) {
    return <Status className="m-auto" status="loading" size="moa" />
  }

  if (error) {
    return (
      <LoggedInLayout>
        <GeneralErrorPage />
      </LoggedInLayout>
    )
  }

  const user = data?.clinicianUser
  const defaultFacilityId = user?.facilityConfigurations[0]?.facility.id

  const redirectFacilityId = currentFacilityId || defaultFacilityId

  const allowedPaths = ['notifications', 'alerts', 'visits', 'settings', 'form']

  if (!facilityId || !defaultFacilityId) {
    return null
  }

  const hasAccessToThisFacility =
    user?.facilityConfigurations.findIndex((facilityConfiguration) => {
      return facilityConfiguration.facility.id === facilityId
    }) !== -1

  // For the five main sections of the app, if we are missing the facilityId from the beginning of the path, then get the first facility config and add it to the front of the current path.
  if (allowedPaths.includes(facilityId)) {
    return <Navigate to={`/${redirectFacilityId}${pathname}`} />
  }

  if (!hasAccessToThisFacility) {
    return <Navigate to={`/${redirectFacilityId}`} />
  }

  addValueToLocalStorage('facilityId', facilityId)

  return (
    <FacilityProvider>
      <LoggedInLayout>
        <Routes>
          <Route path="/" element={<Navigate to={Paths.Alerts} />} />

          <Route path="/notifications/*" element={<NotificationsRedirect />} />

          <Route path={`${Paths.Alerts}/*`} element={<AlertsIndexPage />} />

          <Route path={`${Paths.UserSettings}/*`} element={<UserSettings />} />

          <Route path={Paths.FacilitySettings} element={<Settings />} />

          <Route path={`${Paths.Visits}/*`} element={<Patients />} />

          <Route
            path={`${Paths.TypeformForm}/:formKey/:visitId?`}
            element={<TypeformForm />}
          />

          {/* Catch All */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </LoggedInLayout>
    </FacilityProvider>
  )
}
