import { Body, Button, Truncate } from '@katoa/anatomy'
import type { FC } from 'react'
import { Paths } from '../../../config/app'
import AlertsToggle from '../alerts-toggle/alerts-toggle'
import { FacilityPicker } from '../facility-picker/facility-picker'

export interface GlobalHeaderProps {
  showAlertsToggle?: boolean
  userName?: string | null
}

const GlobalHeader: FC<GlobalHeaderProps> = ({
  userName,
  showAlertsToggle,
}) => {
  return (
    <div className="flex w-full flex-col justify-between gap-3 bg-white lg:flex-row">
      <div>
        <FacilityPicker />
      </div>
      <div
        className="flex items-center justify-between gap-8"
        data-testid="global-header:current-user-name"
      >
        <Button
          element="link"
          collapsed={true}
          intent="neutral"
          to={Paths.UserSettings}
          data-testid="global-header:user-settings-link"
        >
          <Body asChild={true}>
            <div>
              <strong>
                <Truncate>{userName}</Truncate>
              </strong>
            </div>
          </Body>
        </Button>
        {showAlertsToggle && <AlertsToggle />}
      </div>
    </div>
  )
}

export default GlobalHeader
