import type { FC, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export interface StackProps {
  children?: ReactNode
  className?: string | undefined
  element?:
    | 'div'
    | 'ul'
    | 'ol'
    | 'dl'
    | 'section'
    | 'header'
    | 'footer'
    | 'article'
    | 'main'
  spacing: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 'half' | 'quarter'
}

export const Stack: FC<StackProps> = ({
  element = 'div',
  spacing = 0,
  className,
  children,
  ...attributes
}) => {
  const Element = element ?? 'div'

  return (
    <Element
      className={twMerge(
        'relative w-full',
        spacing === 0 && 'space-y-0',
        spacing === 'quarter' && 'space-y-1',
        spacing === 'half' && 'space-y-2',
        spacing === 1 && 'space-y-4',
        spacing === 2 && 'space-y-6',
        spacing === 3 && 'space-y-8',
        spacing === 4 && 'space-y-12',
        spacing === 5 && 'space-y-16',
        spacing === 6 && 'space-y-24',
        spacing === 7 && 'space-y-40',
        className,
      )}
      {...attributes}
    >
      {children}
    </Element>
  )
}
